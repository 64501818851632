
import { Component, Vue, } from 'vue-property-decorator'
import { ApplicationInterface, } from '@/store/types/Applications/Application'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import * as validations from '@/utils/Validations'

@Component({
  components: {},
})
export default class AddApplicationsComponent extends Vue {
  private project_name = ''
  private domain = ''
  private api_token = ''
  private email = ''
  private application = 'jira'
  private applicationSelected = this.$store.state.ApplicationModule.ApplicationState.appSelected
  private validator = validations
  private loading = false;

  mounted () {
    this.project_name = this.applicationSelected.project_name
    this.domain = this.applicationSelected.domain
    this.api_token = this.applicationSelected.api_token
    this.email = this.applicationSelected.email
  }

  private saveFrom () {
    if (
      this.project_name === '' ||
      this.domain === '' ||
      this.api_token === '' ||
      this.email === ''
    ) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Por Favor llenar los campos requeridos',
        color_notification: 'danger',
      })
      return
    }

    // Validacion de email

    if (!validations.regexEmail.test(this.email)) {
      return this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Existe un email inválido',
        color_notification: 'danger',
      })
    }

   this.loading = true

    const formApplication: ApplicationInterface = {
      id: this.applicationSelected.id,
      application: this.application,
      email: this.email,
      api_token: this.api_token,
      domain: this.domain,
      project_name: this.project_name,
    }

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Creando',
    })
    // this.$store
    //   .dispatch('ApplicationModule/updateApp', formApplication)
    //   .then(() => {
    //     this.resetVars()
    //     this.loading = false
    //     this.$store.dispatch('NotificactionsModule/OpenNotification', {
    //       message: 'Éxito, Empresa creada',
    //     })
    //     this.$router.push('/startup/applications')
    //   })
    //   .catch(() => {
    //     this.loading = false
    //   })
  }

  private resetVars () {
    this.project_name = ''
    this.domain = ''
    this.api_token = ''
    this.email = ''
    this.application = ''
  }
}
